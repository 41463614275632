import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/timetable/timetable-choose1.png'
import _imports_1 from '@/assets/image/timetable/timetable-choose2.png'


const _hoisted_1 = { class: "check-or-teach" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logout_icon = _resolveComponent("logout-icon")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_logout_icon),
    _createVNode(_component_a_card, {
      hoverable: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCardClick(1)))
    }, {
      cover: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "left"
        }, null, -1)
      ])),
      default: _withCtx(() => [
        _createVNode(_component_a_card_meta, null, {
          description: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "check-or-teach__card--title" }, "点名上课", -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_card, {
      hoverable: "",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCardClick(2)))
    }, {
      cover: _withCtx(() => _cache[4] || (_cache[4] = [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "right"
        }, null, -1)
      ])),
      default: _withCtx(() => [
        _createVNode(_component_a_card_meta, null, {
          description: _withCtx(() => _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "check-or-teach__card--title" }, "进入课件", -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}